import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide,nextTick} from 'vue';
import DzzlListUtil ,{IDzzlListDataObj} from './dzzlListUtil';
import FormalOrderDetail from "@/views/project/order/order/formalOrder/formalOrderDetail/FormalOrderDetail.vue";
import PiCard from "@/views/project/order/order/pi/PiCard.vue";
export default defineComponent ({
    name: 'DzzlList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IDzzlListDataObj=reactive<IDzzlListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                // showRadio:true,
                isShowFixCol:false,
                queryParam: {},
                cardFrom:'DzzlList',
                modelMethod: utils.OrderProviderApi.buildUrl('/dzzl/pageData')
            },
            otherParams:{
            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new DzzlListUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'status'==params.comboId){
                    return [{value:0,label:'未发运'},{value:1,label:'已维护货代信息'}]
                }
            }
        })

        const formatPageInfo=(options:any)=>{
            return options;
        }
        //查看订单分配和维护等详细信息
        const orderDetail=async (row:any)=>{
            await utils.UtilPub.openDialog({id:'',proxy:proxy,addOrEdit:'add',modelComp:FormalOrderDetail,ownerComp:dataObj.pageListRef,
                orderId:row.F_ORDER_ID,childOrderCode:row.F_ORDER_CODE,childOrderid:row.F_ID,type:row.F_TYPE,
                cardFrom:'DzzlList',overflow:'hidden',title: '订单详情',contentHeight:'',showFooterBtn:false,fullscreen:true})
        }
        //查看pi弹出框
        const piDetail=async (piId:string)=>{
            await utils.UtilPub.openDialog({proxy:proxy,addOrEdit:'edit',id:piId,modelComp:PiCard,ownerComp:proxy,cardFrom:'DzzlList',
                title:'pi详情',contentHeight:'',overflow:'hidden',showFooterBtn:false,fullscreen:true})
        }

        return{
            ...toRefs(dataObj),comboSelect,formatPageInfo,orderDetail,piDetail
        }
    }
});